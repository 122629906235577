import React, { useState, useEffect } from "react";
import { Row, Col, Space, Avatar } from "antd";
import Logo from "../../assets/png/logo.png";
import ModalRegistration from "components/Admin/Partials/ModalRegistration";
import { NAV_MENU_IMTES } from "constants/navigation_constants";
import { Link, useHistory } from "react-router-dom";
import "./HomeLayout.css";
import { RoundButton } from "components/ButtonWidget/RoundButton/RoundButton";
import { IconWidget } from "components/IconWidget/IconWidget";
import ImgAvatar from 'assets/png/avatar.png';

const NavDrawer = () => {
  const history = useHistory();
  const [expandedMenuIndex, setExpandedMenuIndex] = useState(null);
  const [modal2Open, setModal2Open] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isAuth, setIsAuth] = useState(null);
  const handleShowLogin = () => {
    // setIsLoggingIn(true);  // Set to true when the user attempts to log in

    if (localStorage.getItem("client_token")) {
      setIsLoggingIn(false);  // Set to false when we check that the user is already authenticated
    } else {
      window.location.href = "/signin";  // Navigate to the signin page if not authenticated
    }

    // setTimeout(() => {
    //   setIsLoggingIn(false);  // Hide the button after a brief delay (e.g., 2 seconds)
    // }, 2000); // Adjust the delay time as needed (2000ms = 2 seconds)
  };

  const handleShowRegistration = () => {
    if (localStorage.getItem("client_token")) {
      setIsLoggingIn(false);
    } else {
      window.location.href = "/signup";
    }
  };

  const getActiveLink = (route) => {
    if (history.location.pathname === route) {
      return true;
    } else {
      if (route !== "/" && history.location.pathname.includes(route)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const isAuth = localStorage.getItem("client_token");
    setIsAuth(isAuth);
  }, []);

  const handleMenuClick = (index) => {
    setExpandedMenuIndex(expandedMenuIndex === index ? null : index);
  };
  const items = [
    { key: '1', label: <Link to="/cbl" rel="noopener noreferrer">CBL</Link> },
    { key: '2', label: <Link to="/jcbl" rel="noopener noreferrer">JCBL</Link> },
    { key: '3', label: <Link to="/cbl-high-school" rel="noopener noreferrer">CBL High School</Link> },
  ];

  const renderItems = () => {
    return items.map(item => (
      <div key={item.key} className={item.key !== "2" ? "nav-item" : "nav-item-gap"}>
        <Link to={item.path} className="competitions-letter">
          {item.label}
        </Link>
      </div>
    ));
  };

  const menuUI = NAV_MENU_IMTES.map((info, index) => {
    const isCompetition = info.title === "COMPETITIONS";
    return isCompetition ? (
      <div key={index} menu={{ items }} className="nav-menu-section">
        <Col
          className={`nav-menu-item ${getActiveLink(info.route) ? "active" : ""}`}
          onClick={() => handleMenuClick(index)}
        >
          <Space>
            {info.title}
          </Space>
        </Col>

        {expandedMenuIndex === index && (
          <Row className="nav-sub-items" style={{ flexDirection: 'column' }}>
            {renderItems()}
          </Row>
        )}
      </div>
    ): (
      <div key={index} menu={{ items }} className="nav-menu-section">
        <Row key={index} className={`nav-menu-item ${getActiveLink(info.route) ? "active" : ""}`}>
          <Col onClick={() => history.push(info.route)}>
            <Space>{info.title}</Space>
          </Col>
        </Row>
      </div>
    )
  });

  return (
    <nav className="nav-drawer">
      <div>
        <Link to="/"><IconWidget src={Logo} className="nav-logo" /></Link>
      </div>
      <Row className="text-left mt-40">
        {menuUI}
      </Row>
      <div style={{ 
        position: "absolute", 
        bottom: 20, 
        left: 0, 
        right: 0,
        padding: '1px', 
        background: 'none' 
        }}>
        {isAuth ? (
          <Avatar size="large" src={ImgAvatar} />
        ) : (
          <div className="d-flex justify-center">
            <div style={{ padding: '1px', marginRight:'10px', background: 'none', border: !isAuth ? '1px solid var(--darkPurple)' : 'none' }}>
              <RoundButton onClick={handleShowLogin}>LOGIN</RoundButton>
            </div>
            <div style={{ padding: '1px', marginRight:'10px', background: 'none', border: !isAuth ? '1px solid var(--darkPurple)' : 'none' }}>
              <RoundButton onClick={handleShowRegistration} style={{backgroundColor:'#1b4c98'}}>SIGN UP</RoundButton>
            </div>
          </div>
        )}
        {modal2Open && <ModalRegistration  modal2Open={modal2Open} setModal2Open={setModal2Open} />}
      </div>
    </nav>
  );
};

export default NavDrawer;