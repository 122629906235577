import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import DashboardLayout from 'layouts/AdminLayout/AdminLayout';

function Dashboard({ history }) {
    useEffect(() => {
        const token = localStorage.getItem('token');
        
        // Check for the token
        if (!token) {
            history.push('/admin/login'); // Redirect to login if no token is found
        } else {
            history.push('/admin/dashboard');
        }
    }, [history]);

    return (
        <DashboardLayout />
    );
}

export default withRouter(Dashboard);