import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, ConfigProvider, Space, Form, Input, DatePicker, message } from "antd";
import { createStyles } from 'antd-style';
import Logo from "assets/png/logo.png";
import { Link } from "react-router-dom";
import { LinkOutlined, LoginOutlined, ToTopOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons"; 
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

import './index.css';

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(.${prefixCls}-btn-dangerous) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: '';
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
  modalContent: css`
    padding: 20px; 
  `
}));

const ModalRegistration = ({ modal2Open, setModal2Open }) => {
  const [isCblJcbl, setCblJcbl] = useState(false);
  const [isHighSchool, setHighSchool] = useState(false);
  const [modalSignin, setModalSignin] = useState(false);
  const [modalSignup, setModalSignup] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  const [signinResponse, setSigninResponse] = useState("");
  const [signupResponse, setSignupResponse] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const { styles } = useStyle();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/open_season`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.data.some(item => item.type === "CBL" || item.type === "JCBL")) {
          setCblJcbl(true);
        }
        if (response.data.some(item => item.type === "CBL High School")) {
          setHighSchool(true);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAuthStatus = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/auth`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('client_token')}`,
          },
        }
      );

      if (response.data.status != "success") {
        localStorage.removeItem("client_token");
      }
    }
    if (localStorage.getItem("client_token")) setIsAuth(true);


    fetchData();
    fetchAuthStatus();
  }, []); 


  
  const handleSignIn = () => {
    window.location.href="/signin";
  }

  const   handleSignUp = () => {
    window.location.href="/signup";
  }

  const onSigninFinish = async (values) => {
    console.log("Form Values:", values);
    try {
      const formData = new FormData();
      formData.append("password", values.password);
      formData.append("email", values.email);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/signin`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('clientToken')}`,
          },
        }
      );
  
      if (response.data.status == "success") {
        localStorage.setItem("client_token", response.data.token);
        setIsAuth(true);
        setModalSignin(false);
      } else {
        setSigninResponse(response.data.message);
      }
    } catch (error) {
      console.error("Signin error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="auth-content">
      <Modal
        wrapClassName="vertical-center-modal"
        open={modal2Open}
        onOk={() => setModal2Open(false)}  // You can remove or keep these handlers depending on whether you need to manage the state
        onCancel={() => setModal2Open(false)}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <img src={Logo} alt="Modal Image" style={{ maxWidth: '100%', marginBottom: '20px' }} />
          </Link>
        </div>

        <Row className="btn-cbl-content">
          <Col style={{ width: '270px' }}>
            {isCblJcbl && (
              <Link to={isAuth ? "/register_individual" : "#"}>
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  className={`btn-cbl-register ${!isAuth ? 'disabled-button' : ''}`}
                  disabled={!isAuth}
                >
                  Register Individual for CBL & JCBL
                </Button>
              </Link>
            )}
            
            {isHighSchool && (
              <Link to={isAuth ? "/register_team" : "#"}>
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  className={`btn-cbl-register ${!isAuth ? 'disabled-button' : ''}`}
                  disabled={!isAuth}
                >
                  Register Team for CBL High School
                </Button>
              </Link>
            )}
            <ConfigProvider
              button={{
                className: styles.linearGradientButton,
              }}
            >
              { !isAuth && (
                <p style={{color: 'red'}}>Please sign in to register for the season.</p>
              )}
              <Space
                style={{ 
                  width: '100%', 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  marginBottom:'20px',
                  marginTop:'10px' 
                }}
              >
                {/* <Button 
                  disabled={isAuth} 
                  type="primary" 
                  size="middle" 
                  icon={<LoginOutlined />} 
                  style={{ padding: '0 33px' }} 
                  onClick={() => handleSignIn()}
                >
                  SignIn
                </Button>
                <Button 
                  disabled={isAuth} 
                  type="primary" 
                  size="middle" 
                  icon={<ToTopOutlined />} 
                  style={{ padding: '0 33px' }} 
                  onClick={() => handleSignUp()}
                >
                  SignUp
                </Button> */}
              </Space>
            </ConfigProvider>
          </Col>
        </Row>
        {!isCblJcbl && !isHighSchool && (
          <div style={{ textAlign: 'center', color: '#f00', fontSize:'19px' }}>
            There is no season. Wait a moment, please.
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalRegistration;
