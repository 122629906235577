import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, Input, DatePicker, Row, Col, Radio, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Toast from 'layouts/Partials/Toast';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import moment from 'moment'; 
import './index.css';

const SeasonSettings = () => {
  const { Option } = Select;
  const [seasons, setSeasons] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingSeason, setEditingSeason] = useState(false);
  const [statusValue, setStatusValue] = useState('Open'); 
  const [dataSource, setDataSource] = useState([]); 
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  
  const onStatusChange = (e) => {
    setStatusValue(e.target.value);
  };

  const handleSubmit = async (values) => {
    const { memberType, seasonName, startDate, endDate, status, registration_start_date, registration_end_date } = values;
    if (!memberType || !seasonName || !startDate || !endDate || !registration_start_date || !registration_end_date) {
      Toast('Please fill in all fields before submitting.', 2);
      return;
    }
  
    const seasonData = {
      memberType,  
      seasonName,
      startDate: startDate.format('YYYY-MM-DD'), 
      endDate: endDate.format('YYYY-MM-DD'),     
      status: status === 'Open' ? '1' : '0',    
      registration_start_date: registration_start_date.format('YYYY-MM-DD'),
      registration_end_date: registration_end_date.format('YYYY-MM-DD')
    };
  
    try {
      if (editingSeason) {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/season/${editingSeason.key}`, seasonData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
  
        setSeasons(seasons.map(season => 
          season.key === editingSeason.key ? { ...season, ...seasonData } : season
        ));
  
        setDataSource(dataSource.map(season => 
          season.key === editingSeason.key ? { ...season, ...seasonData } : season
        ));
  
        Toast('Season updated successfully', 1);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/season`, seasonData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
  
        const newSeason = {
          key: response.data.id,
          ...seasonData,
        };
  
        setSeasons(prevSeasons => [...prevSeasons, newSeason]);
        setDataSource(prevDataSource => [...prevDataSource, newSeason]);
        Toast('Season created successfully', 1);
      }

      resetForm();
    } catch (error) {
      Toast('Failed to save season. Please try again.', 2);
      console.error('Error saving season:', error);
    }
  };
  
  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: newData.length,
      current: Math.ceil(newData.length / prevPagination.pageSize),
    }));

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/season/${key}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.status === "success") {
        Toast('Deleted successfully!', 1);
      } else {
        Toast('There was an error on the server!', 2);
      }
    } catch (error) {
      Toast('Failed to delete season. Please try again.', 2);
      console.error('Error deleting season:', error);
    }
  };

  const resetForm = () => {
    setEditingSeason(null);
    setIsModalVisible(false);
    setStatusValue('Open'); 
  };

  const handleEdit = (record) => {
    setEditingSeason(record); 
    setIsModalVisible(true); 
  };

  const handleDateChange = (date, fieldName) => {

    // setEditingSeason({
    //   ...editingSeason,
    //   [fieldName]: date ? moment(date) : null,
    // });
  };

  const columns = [
    {
      title: 'Member Type',
      dataIndex: 'memberType',
    },
    {
      title: 'Season Name',
      dataIndex: 'seasonName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
    },
    {
      title: 'Registration Start Date',
      dataIndex: 'registration_start_date',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
    },
    {
      title: 'Registration End Date',
      dataIndex: 'registration_end_date',
      render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <span>{record.status === '1' ? 'Open' : 'Close'}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <>
            <Button onClick={() => handleEdit(record)} className="btn-edit-season">
              <EditOutlined />
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/season`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const fetchedSeasons = response.data.map(item => ({
          key: item.id,
          memberType: item.type,  
          seasonName: item.name || '', 
          startDate: item.start_date ? moment(item.start_date) : null,
          endDate: item.end_date ? moment(item.end_date) : null,      
          status: item.status, 
          registration_start_date: item.registration_start_date ? moment(item.registration_start_date) : null,
          registration_end_date: item.registration_end_date ? moment(item.registration_end_date) : null,
        }));

        setSeasons(fetchedSeasons);
        setDataSource(fetchedSeasons);
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: fetchedSeasons.length,
        }));
      } catch (error) {
        Toast('Failed to fetch data. Please try again later.', 2);  
      }
    };

    fetchData();
  }, []);

  return (
    <AdminLayout>
      <Row className="d-flex justify-between">
        <Col><h1>Season Management</h1></Col>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ height: '44px', marginLeft: '10px' }}>
            Add Season
          </Button>
        </Col>
      </Row>

      <Table 
        dataSource={dataSource}
        columns={columns} 
        style={{ marginTop: '16px' }} 
        pagination={pagination} 
      />
      <Modal
        key={editingSeason?.key}
        title={editingSeason ? "Edit Season" : "Add Season"}
        visible={isModalVisible}
        onCancel={resetForm}
        footer={null}
      >
        <Form
          onFinish={handleSubmit} 
          initialValues={{
            memberType: editingSeason?.memberType || '',
            seasonName: editingSeason?.seasonName || '',
            startDate: editingSeason?.startDate ? moment(editingSeason.startDate) : null,
            endDate: editingSeason?.endDate ? moment(editingSeason.endDate) : null,
            registration_start_date: editingSeason?.registration_start_date ? moment(editingSeason.registration_start_date) : null,
            registration_end_date: editingSeason?.registration_end_date ? moment(editingSeason.registration_end_date) : null,
            status: editingSeason?.status === '1' ? 'Open' : 'Close',
          }}
          className="mt-20 admin-season"
        >
        <Form.Item
            label="Set Member Type"
            name="memberType"
            rules={[{ required: true, message: '' }]}
        >
            <Select placeholder="Select Member Type">
              <Option value="CBL">CBL</Option>
              <Option value="JCBL">JCBL</Option>
              <Option value="High School">High School</Option>
              <Option value="Academy">Academy</Option>
            </Select>
        </Form.Item>

        <Form.Item
            label="Season Name"
            name="seasonName"
            className="mt-20"
            rules={[{ required: true, message: '' }]}
        >
            <Input placeholder="Enter Season Name" />
        </Form.Item>

        <Form.Item
            label="Season Start Date"
            name="startDate"
            rules={[{ required: true, message: '' }]}
        >
            <DatePicker placeholder="Select Start Date" value={editingSeason?.startDate || null} />
        </Form.Item>

        <Form.Item
            label="Season End Date"
            name="endDate"
            rules={[{ required: true, message: '' }]}
        >
            <DatePicker placeholder="Select End Date" value={editingSeason?.endDate || null} />
        </Form.Item>

        <Form.Item
            label="Registration Start Date"
            name="registration_start_date"
            rules={[{ required: true, message: '' }]}
        >
            <DatePicker 
              placeholder="Select Registration Start Date"
              value={editingSeason?.registration_start_date ? moment(editingSeason.registration_start_date) : null}
              onChange={(date) => handleDateChange(date, 'registration_start_date')}
            />
        </Form.Item>

        <Form.Item
            label="Registration End Date"
            name="registration_end_date"
            rules={[{ required: true, message: '' }]}
        >
            <DatePicker 
              placeholder="Select Registration End Date"
              value={editingSeason?.registration_end_date ? moment(editingSeason.registration_end_date) : null}
              onChange={(date) => handleDateChange(date, 'registration_end_date')}
            />
        </Form.Item>

        <Form.Item label="Status" name="status">
            <Radio.Group value={editingSeason?.status === '1' ? 'Open' : 'Close'} onChange={onStatusChange}>
                <Radio value="Open">Open</Radio>
                <Radio value="Close">Close</Radio>
            </Radio.Group>
        </Form.Item>

        <Form.Item>
            <div className="d-flex justify-end">
                <Button type="primary" htmlType="submit">Save</Button>
                <Button style={{ marginLeft: '8px' }} onClick={resetForm}>Cancel</Button>
            </div>
        </Form.Item>
        </Form>
      </Modal>
    </AdminLayout>
  );
};

export default SeasonSettings;