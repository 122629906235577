import React, { useState, useEffect } from 'react';
import { Card, Row, Col} from 'antd';
import Toast from 'layouts/Partials/Toast';
import { Link } from "react-router-dom";
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import './index.css';

const Dashboard = () => {
    const [activeUserCount, setActiveUserCount] = useState(0);
    const [deletedUserCount, setDeletedUserCount] = useState(0);
    const [cblCount, setCblCount] = useState(0);
    const [jcblCount, setJcblCount] = useState(0);
    const [academyCount, setAcademyCount] = useState(0);
    const [highschoolCount, setHighschoolCount] = useState(0);

    const animateCount = (targetCount, setCount) => {
        let currentCount = 0;
        const interval = setInterval(() => {
            if (currentCount < targetCount) {
                currentCount += Math.ceil(targetCount / 100);
                if (currentCount > targetCount) {
                    currentCount = targetCount;
                }
                setCount(currentCount);
            } else {
                clearInterval(interval); 
            }
        }, 70);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (response.data.status === "success") {
                    animateCount(response.data.cbl_count, setCblCount);
                    animateCount(response.data.jcbl_count, setJcblCount);
                    animateCount(response.data.academy_count, setAcademyCount);
                    animateCount(response.data.highschool_count, setHighschoolCount);
                    animateCount(response.data.active_user_count, setActiveUserCount);
                    animateCount(response.data.deleted_user_count, setDeletedUserCount);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <AdminLayout>
            <Row className="d-flex justify-between">
                <Col><h1>Dashboard Management</h1></Col>
            </Row>

            <Row gutter={32} className="admin-dashbaord">
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/cbl" rel="noopener noreferrer">
                        <Card title="CBL" bordered={false}>
                            <p>{cblCount}</p>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/cbl" rel="noopener noreferrer">
                        <Card title="JCBL" bordered={false}>
                            <p>{jcblCount}</p>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/user" rel="noopener noreferrer">
                        <Card title="Active User" bordered={false}>
                            <p>{activeUserCount}</p>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/user" rel="noopener noreferrer">
                        <Card title="Deleted User" bordered={false}>
                            <p>{deletedUserCount}</p>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/cbl" rel="noopener noreferrer">
                        <Card title="High School" bordered={false}>
                            <p>{academyCount}</p>
                        </Card>
                    </Link>
                </Col>
                <Col xs={24} sm={12} md={8} xl={4} className="mb-20">
                    <Link to="/cbl" rel="noopener noreferrer">
                        <Card title="Academy" bordered={false}>
                            <p>{highschoolCount}</p>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </AdminLayout>
    );
};

export default Dashboard;