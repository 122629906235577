import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Button, Col, Input, Row, Select, message, Typography, Upload, DatePicker, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import "./Register1Page.css";
import ImgRegisterLogo from "../../assets/png/registerlogo.jpg"
import ImgOverlayLogo from "../../assets/png/overlay-logo.png"
import PaintCanvas from "components/Partials/PaintCanvas";
import axios from 'axios';

const { Option } = Select;
const { Text } = Typography;
const { Dragger } = Upload;

const Register1Page = ({ history }) => {
    const [signatureData, setSignatureData] = useState(null);
    const [seasonData, setSeasonData] = useState([]);
    const [selectSeason, setSelectSeason] = useState([]);
    const [formData, setFormData] = useState({
        school_name: '',
        address: '',
        city: '',
        postal_code: '',
        contact_number: '',
        email: '',
        team_name: '',
        age_group: '',
        coach_name: '',
        coach_contact_number: '',
        coach_email: '',
        player_number: '',
        uploaded_player_data: '',
        approving_school_name: '',
        position: '', 
        signature_path: '',
        signature_date: '',
        registration_fee:''
    });

    const openDangerNotification = (props) => {
        notification.config({
            placement: 'bottomLeft', // Set the position to bottom left
            duration: 2,
        });
    
        let msg = '';
        let notificationType = '';
    
        if (props === 1) {
            msg = "Registration successful!";
            notificationType = 'success'; // Set to success for successful registration
        } else if (props === 2) {
            msg = "Registration failed, please try again.";
            notificationType = 'error'; // Set to error for failed registration
        } else {
            msg = "An error occurred during registration.";
            notificationType = 'error'; // General error message
        }
    
        // Call the appropriate notification based on notificationType
        if (notificationType === 'success') {
            notification.success({
                message: 'Success Notification',
                description: msg,
            });
        } else {
            notification.error({
                message: 'Error Notification',
                description: msg,
            });
        }
    };
    
  
    const doRegister = async () => {
        console.log(formData);
        const { uploaded_player_data, signature_path, ...rest } = formData;

        const formDataToSend = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });

        if (uploaded_player_data) {
            formDataToSend.append('uploaded_player_data', uploaded_player_data);
        }

        if (signatureData) {
            const blob = await (await fetch(signatureData)).blob(); // Convert to Blob
            formDataToSend.append('signature_path', blob, 'signature.png'); // Append Blob
        }

        formDataToSend.append('user_id', localStorage.getItem('user_id'));
        console.log(formDataToSend);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/high_school`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                openDangerNotification(1);
            } else {
                openDangerNotification(2);
            }
        } catch (error) {
            openDangerNotification(3);
        }
    }

    const handleInputChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleage_groupChange = (value) => {
        setFormData({ ...formData, age_group: value });
    };

    const handlefee_groupChange = (value) => {
        setFormData({ ...formData, registration_fee: value });
    };

    const handleSeasonGroupChange = (value) => {
        setFormData({ ...formData, season: value });
        const item = seasonData.find((item) => item.id === value);
        setSelectSeason(item);
    };

    const handleUploadPlayerDataChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        if (info.fileList.length > 0) {
            setFormData({ ...formData, uploaded_player_data: info.fileList[0].originFileObj });
        }
    };

    const handleSignatureChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        if (info.fileList.length > 0) {
            setFormData({ ...formData, signature_path: info.fileList[0].originFileObj });
        }
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        action: null,
        onChange: handleUploadPlayerDataChange,
        beforeUpload: (file) => {
            if (file.size > 5 * 1024 * 1024) { // 5MB size limit
                message.error('File is too large!');
                return false;
            }
            return true;
        }
    };

    const signatureUploadProps = {
        name: 'file',
        multiple: false,
        action: null,
        onChange: handleSignatureChange,
    };

    useEffect(() => {
        const fecthSeasonData = async () => {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/open_type_season`, 
                {'season_type':'highschool'},
                {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setSeasonData(response.data.data);
            console.log("dddd", response.data.data);
        }
        fecthSeasonData();
    }, []); 

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          history.push('/admin/login'); 
        }
      }, [history]);

      
    return (
        <HomeLayout>
            <div className='register-content'>
                <div className='register-adv'>
                    <h1>JOIN THE GAME <span>REGISTER</span> YOUR</h1>
                    <h1> INTEREST <span>NOW!</span></h1>
                    <p>Ready to showcase your skills? Whether you're aiming for the big leagues in the CBL or starting your journey in the JCBL,
                        this is your first step to becoming a part of Coastal Basketball. Register now to get started!
                    </p>

                    {/* <button className='btn-register home-little-letter white bold'>REGISTER NOW</button> */}
                </div>

                <div className='register-logo'>
                    <img src={ImgRegisterLogo} />
                    <div className="logo-overlay">
                        <img src={ImgOverlayLogo} alt="CBL Logo" className="center-logo" />
                    </div>
                </div>

                <div className='register-form'>
                    <div className='register-form-title'>
                        <h1>COASTAL BASKETBALL HGIH SCHOOL LEAGUE REGISTRATION FROM</h1>
                    </div>
                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>SEASON</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Select placeholder="SEASON" name="season" style={{ width: '100%' }} onChange={handleSeasonGroupChange}>
                                {seasonData.map(season => (
                                    <Option key={season.id} value={season.id}>
                                    {season.name}
                                    </Option>
                                ))}
                            </Select>
                            {selectSeason != undefined && selectSeason.id != null && (
                                <p className="text-left" 
                                   style={{
                                    marginTop:'5px', 
                                    color:'#515151', 
                                    fontWeight:'bold', 
                                    lineHeight:'1.7'
                                    }}
                                >
                                    Season Type: {selectSeason.type} <br/>
                                     Start Date: {selectSeason.start_date} <br/>
                                       End Date: {selectSeason.end_date}
                                </p>
                            )}
                        </Col>
                    </Row>
                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>SCHOOL INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="SCHOOL NAME"
                                        name="school_name"
                                        value={formData.school_name}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="ADDRESS"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="CITY/SUBURB"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="POSTAL CODE"
                                        name="postal_code"
                                        value={formData.postal_code}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="CONTACT NUMBER"
                                        name="contact_number"
                                        value={formData.contact_number}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="EMAIL ADDRESS"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>TEAM INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="TEAM NAME"
                                        name="team_name"
                                        value={formData.team_name}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Select placeholder="AGE GROUP" 
                                        className="age-group-select" 
                                        value={formData.age_group || undefined} 
                                        onChange={handleage_groupChange}
                                        >
                                        <Option value="Under 10 years old">Under 10 years old</Option>
                                        <Option value="Under 20 years old">Under 20 years old</Option>
                                        <Option value="Under 30 years old">Under 30 years old</Option>
                                        <Option value="Under 40 years old">Under 40 years old</Option>
                                        <Option value="Under 50 years old">Under 50 years old</Option>
                                        <Option value="Under 60 years old">Under 60 years old</Option>
                                        <Option value="Under 80 years old">Under 80 years old</Option>
                                    </Select>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="NUMBER OF PLAYERS"
                                        name="player_number"
                                        value={formData.player_number}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="COACH NAME"
                                        name="coach_name"
                                        value={formData.coach_name}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="COACH CONTACT NUMBER"
                                        name="coach_contact_number"
                                        value={formData.coach_contact_number}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input
                                        placeholder="COACH EMAIL ADDRESS"
                                        name="coach_email"
                                        value={formData.coach_email}
                                        onChange={handleInputChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>PLAYER DETAILS</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <div className="upload-section">
                                <Text className="upload-title">Upload Player Roster (Excel/PDF)</Text>
                                <Dragger {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">CLICK TO UPLOAD OR DRAG & DROP</p>
                                </Dragger>
                                <Text className="upload-instructions">
                                    <span className="upload-instructions-title">Instructions:</span> Include player names, dates of birth, and student ID numbers.
                                </Text>
                            </div>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>CONSENT & AGREEMENT</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <div className="consent-form">
                                <h3>Consent for Use of Images and Logos</h3>
                                <p>
                                    By registering for the Coastal Basketball High School League, the school consents to the use of its logo, team, and player images in promotional materials, including the league’s website, social media, and other media. The school confirms that all necessary parental consents have been obtained for players under 18.
                                </p>
                                <div className="form-fields">
                                    <Input
                                        className="form-input" 
                                        name="approving_school_name" 
                                        placeholder="NAME OF APPROVING SCHOOL OFFICIAL"
                                        onChange={handleInputChange} 
                                    />
                                    <Input 
                                        className="form-input" 
                                        name="position" 
                                        placeholder="POSITION"
                                        onChange={handleInputChange} 
                                    />
                                    <Input 
                                        className="form-input" 
                                        placeholder="POSITION" 
                                    />
                                </div>
                                <Row className="signature-section" gutter={[32, 32]}>
                                    <Col xs={24} md={12}>
                                        <Text>Signature:</Text>
                                        <div className="signature-uploader">
                                            <PaintCanvas setSignatureData={setSignatureData} /> {/* Pass setter to child */}
                                            <p className="ant-upload-text">CLICK TO SIGN</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Text>Date:</Text>
                                        <DatePicker
                                            className="date-picker"
                                            placeholder="SELECT A DATE"
                                            name="signature_date"
                                            onChange={(date, dateString) => setFormData({ ...formData, signature_date: dateString })}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>PAYMENT INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <div className="payment-form">
                                <h3>Registration Fee</h3>
                                <Text className="fee-description">
                                    Text information about registration fee’s amount.
                                </Text>

                                <Select 
                                    className="payment-select" 
                                    value={formData.registration_fee || undefined} 
                                    placeholder="PAYMENT METHOD"
                                    onChange={handlefee_groupChange}
                                    >
                                    <Option value="credit card">Credit Card</Option>
                                    <Option value="paypal">PayPal</Option>
                                    <Option value="bank transfer">Bank Transfer</Option>
                                </Select>

                                <Button type="primary" className="payment-button home-little-letter white bold">
                                    UPLOAD YOUR PAYMENT INFORMATION
                                </Button>

                                <Text className="payment-security-note" type="danger">
                                    YOUR PAYMENT INFORMATION IS SAFE AND SECURE WITH COASTAL BASKETBALL.
                                </Text>
                            </div>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>SUBMIT</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Button type="primary" className="submit-button home-little-letter white bold" onClick={() => doRegister()}>
                                SUBMIT YOUR FORM
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </HomeLayout>
    )
}

export default withRouter(Register1Page);