import { Col, Row, Button } from "antd"; // Removed Link from here
import { BottomDescription } from "components/Description/BottomDescription/BottomDescription";
import React, { useEffect, useState } from "react"; 
import axios from "axios"; 
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./HomeBranch.css";

const HomeBranch = (props) => {
  const [items, setItems] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const createSlug = (title) => {
    return title.toLowerCase().replace(/\s+/g, '-');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/branch`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const fetchedData = response.data.map(item => ({
          image_path: item.image_path,
          content: item.content,
          title: item.title,
          logo_path: item.logo_path,
          image_path_status: item.image_path_status, // Include image path status
          logo_path_status: item.logo_path_status    // Include logo path status
        }));
        setItems(fetchedData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  return (
    <div className="customer-rect">
      <Row className="branch-about-content">
        <Col>
          <BottomDescription
            title1="OUR DYNAMIC"
            title2="BRANCHES"
            content="Explore all of our Branches"
          />
        </Col>
        <Col>
          <BottomDescription
              title1="ABOUT"
              title2="US"
              content="Learn more about our association and how you can get involved."
          />
          <div className="w-100 d-flex justify-center">
            <div className="btn-responsive-more">
              <Button className="btn-about-more" >
                  LEARN MORE
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <div className="customer-main">
        <Row justify="space-around" className="responsive-row mt-40">
          {loading ? (
            <Col span={24} style={{ textAlign: 'center' }}>Loading...</Col>
          ) : (
            items.map((x, index) => (
              <div key={index}>
              {(x.logo_path_status == 1 && x.logo_path) ? (
              <Col>
                <div className="branch-content">
                <Link to={`/${createSlug(x.title)}`}>
                    <div
                      className="image-wrapper"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    > 
                      {/* <img
                        alt="branch"
                        src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${x.logo_path}`} // Update with the correct path
                        style={{ height: '210px'}}
                      /> */}
                        <div className="img-container">
                          <img
                            alt="branch"
                            src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${x.logo_path}`} 
                          />
                        </div>
                  


                    {/* {(x.image_path_status == 1 && x.image_path) ? (
                      <div
                      className="branch-img" 
                      alt="Branch" 
                      style={{
                        backgroundImage: x.image_path_status ? `url(${process.env.REACT_APP_API_BASE_URL}uploads/media/${x.image_path ? x.image_path : 'none'})` : "none"
                      }} 
                      />
                    ) : (
                      <div 
                      className="branch-img" 
                      alt="Branch" 
                      style={{
                        backgroundImage: x.image_path_status ? `url(${process.env.REACT_APP_API_BASE_URL}uploads/media1/${x.image_path ? x.image_path : 'none'})` : "none"
                      }} 
                      />
                    )}

                      {(x.logo_path_status == 1 && x.logo_path) ? (
                      <div 
                      className={`hover-image ${hoveredIndex === index ? 'visible' : ''}`}
                      style={{
                        backgroundImage: x.logo_path_status ? `url(${process.env.REACT_APP_API_BASE_URL}uploads/media/${x.logo_path ? x.logo_path : 'nba_logo.png'})` : "none"
                      }} 
                      />
                    ) : (
                      <></>
                    )} */}

                    </div>
                    <p className="font-13 bold mt-5 text-center home-little-letter">
                      {x.content !== "undefined" ? x.content : ""}
                      {/* <span className="bold" style={{ color: 'var(--redColor)' }}>
                        {x.title !== "undefined" ? x.title : ""}
                      </span> */}
                    </p>
                  </Link>
                </div>
              </Col>
              ):(<></>)}
              </div>
            ))
          )}
        </Row>
      </div>
    </div>
  );
};

export default HomeBranch;
