import React, { useEffect, useState } from "react";
import { Row, Col, Drawer, Dropdown, Space, Avatar, Modal, Button, Menu, ConfigProvider, Typography, Form, Input, DatePicker } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import Logo from "../../assets/png/logo.png";
import ImgNavArrow from "../../assets/png/nav_arrow.png";
import { NAV_MENU_IMTES, ROUTE_COMPETITIONS, ROUTE_CBL, ROUTE_JCBL } from "constants/navigation_constants";
import { Link, useHistory } from "react-router-dom";
import "./HomeLayout.css";
import { RoundButton } from "components/ButtonWidget/RoundButton/RoundButton";
import { IconWidget } from "components/IconWidget/IconWidget";
import ModalRegistration from "components/Admin/Partials/ModalRegistration";
import { MenuOutlined, CloseOutlined, LinkOutlined } from "@ant-design/icons"; 
import ImgAvatar from 'assets/png/avatar.png';
import useStyle from './useStyle';
import NavDrawer from "./NavDrawer";
import dayjs from 'dayjs';
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

const { Text } = Typography;

const HomeNav = () => {
  const history = useHistory();
  const [activeClass, setActiveClass] = useState("");
  const [signinResponse, setSigninResponse] = useState("");
  const [visibleMenu, setVisibleMenu] = useState(false);
  const screenWidthSize = window.innerWidth;
  const [modal2Open, setModal2Open] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const[authInfo, setAuthInfo] = useState([]);
  const { styles } = useStyle();
  const [isAuth, setIsAuth] = useState(true);
  const [modalState, setModalState] = useState({
    loginModal: false,
    profileModal: false,
    registerModal: false,
  });
  
  const toggleModal = (key) => {
    setModalState((prev) => ({ ...prev, [key]: !prev[key] }));
  };
  const [userName, setUserName] = useState("");

  const getActiveLink = (route) => {

    const { pathname } = history.location;
 
    if (pathname === route) {
      return true;
    }

    if (route === ROUTE_COMPETITIONS) {
      return pathname.includes("/cbl") || pathname.includes("/jcbl") || pathname.includes("/cbl-high-school");
    }

    return false;
  };

  const [visiblePassword, setVisiblePassword] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setVisiblePassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  

  const items = [
    { key: '1', label: <Link to="/cbl" rel="noopener noreferrer" className={`nav-active-item ${getActiveLink("/cbl") ? "active" : ""}`}>CBL</Link> },
    { key: '2', label: <Link to="/jcbl" rel="noopener noreferrer" className={`nav-active-item ${getActiveLink("/jcbl") ? "active" : ""}`}>JCBL</Link> },
    { key: '3', label: <Link to="/cbl-high-school" rel="noopener noreferrer" className={`nav-active-item ${getActiveLink("/cbl-high-school") ? "active" : ""}`}>CBL High School</Link> },
  ];
  
  const handleShowLogin = () => {
    // setIsLoggingIn(true);  // Set to true when the user attempts to log in

    if (localStorage.getItem("client_token")) {
      setIsLoggingIn(false);  // Set to false when we check that the user is already authenticated
    } else {
      window.location.href = "/signin";  // Navigate to the signin page if not authenticated
    }

    // setTimeout(() => {
    //   setIsLoggingIn(false);  // Hide the button after a brief delay (e.g., 2 seconds)
    // }, 2000); // Adjust the delay time as needed (2000ms = 2 seconds)
  };

  const handleShowRegistration = () => {
    if (localStorage.getItem("client_token")) {
      setIsLoggingIn(false);
    } else {
      window.location.href = "/signup";
    }
  };

  const onFinish = async (formValues) => {
    if (formValues.new_password === formValues.confirm_password) {
      try {
        // Ensure that the date of birth has no time
        const formattedDateOfBirth = formValues.dateOfBirth ? formValues.dateOfBirth.startOf('day').format('YYYY-MM-DD') : null;
  
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}api/user/api_client_auth_reset`, // Change to your actual API endpoint
          {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            dateOfBirth: formattedDateOfBirth, // Pass the formatted date
            homeAddress: formValues.homeAddress,
            email: formValues.email,
            password: formValues.password,
            new_password: formValues.new_password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
  
        if (response.data.status === 'success') {
          setSigninResponse('Profile updated successfully.');
          setModalVisible(false);
          setUserName(formValues.firstName);
          // console.log("1231332123", response.data);

        } else {
          setSigninResponse('Profile update failed');
        }
      } catch (error) {
        setSigninResponse('Profile update failed');
      }
    } else {
      setSigninResponse("New password doesn't match with confirm password!");
    }
  };
  
  const menuUI = NAV_MENU_IMTES.map((info, index) => (
    info.title === "COMPETITIONS" ? (
      <Dropdown key={index} menu={{ items }}>
        <Col
          className={`nav-menu-item ${getActiveLink(info.route) ? "active" : ""}`}
          onMouseEnter={() => setActiveClass('active')}
          onClick={(e) => e.preventDefault()}
        >
          <Space>
            {info.title}
            <img src={ImgNavArrow} width="26" height="26" alt="nav arrow" />
          </Space>
        </Col>
      </Dropdown>
    ) : (
      <Row
        key={index}
        className={`nav-menu-item ${getActiveLink(info.route) ? "active" : ""}`}
        onClick={() => history.push(info.route)}
      >
        <Space>{info.title}</Space>
      </Row>
    )
  ));
  

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("client_token");
      setIsAuth(token);
      if (token) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}api/auth`,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setUserName(response.data.data[0].first_name);
          setAuthInfo(response.data.data[0]); // set the user info for form defaults
        } catch (error) {
          console.error("Error fetching auth data:", error);
        }
      }
    };
    fetchData();
  }, []);
  
  

  const handleProfile = () => {
    setModalVisible(true);

  };

  const handleSignOut = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/signout`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('client_token')}`
      }
    });

    if (response.data.status == "success") {
      localStorage.removeItem('client_token');
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('user_id');
      setIsAuth(null);
    }

  };

  const handleRegister = () => {
    if (localStorage.getItem("client_token")) {
      setModal2Open(true);
    } else {
        window.location.href = "/signin"
    }
  }

  const menuItems = [
    {
      key: 'user-info',
      disabled: true,
      label: (
        <Space direction="horizontal">
          <Avatar size="large" src={ImgAvatar} />
          <div>
            <Text strong>{userName}</Text>
            <br />
            <Text type="secondary">{authInfo?.email}</Text>
          </div>
        </Space>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'register',
      label: (
        <Button type="link" className="btn-navbar-item" onClick={handleRegister}>
          Register
        </Button>
      ),
    },
    {
      key: 'profile',
      label: (
        <Button type="link" className="btn-navbar-item" onClick={handleProfile}>
          My Profile
        </Button>
      ),
    },
    {
      key: 'logout',
      label: (
        <Button type="link" className="btn-navbar-item" onClick={handleSignOut}>
          Sign out
        </Button>
      ),
    },
  ];

  const menu = <Menu style={{marginTop:'20px'}} items={menuItems} />;

  if (screenWidthSize > 768) {
    return (
      <nav className='nav nav-active client-admin-content'>
        <Row justify="space-between" align="middle" className="nav-rect">
          <Col>
            <Link to="/">
              <IconWidget src={Logo} className="nav-logo" />
            </Link>
          </Col>
          {menuUI}
          <Col>
            
            {isAuth ? (
              <div className="zxc">
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  onOpenChange={setOpen}
                  open={open}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <Avatar size="large" src={ImgAvatar} />
                  </span>
                </Dropdown>
              </div>
            ) : (
              <div className="d-flex">
                <div style={{ padding: '1px', marginRight:'10px', background: 'none', border: !isAuth ? '1px solid var(--darkPurple)' : 'none' }}>
                  <RoundButton onClick={handleShowLogin}>LOGIN</RoundButton>
                </div>
                <div style={{ padding: '1px', marginRight:'10px', background: 'none', border: !isAuth ? '1px solid var(--darkPurple)' : 'none' }}>
                  <RoundButton onClick={handleShowRegistration} style={{backgroundColor:'#1b4c98'}}>SIGN UP</RoundButton>
                </div>
              </div>
            )}
            {modal2Open && <ModalRegistration  modal2Open={modal2Open} setModal2Open={setModal2Open} />}
            <Modal
                wrapClassName="vertical-center-modal"
                open={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                footer={null}
                style={{marginTop:'130px'}}
              >
                <div style={{ textAlign: 'center' }}>
                  <h2 className="mb-20">My Profile</h2>
                </div>
                <ConfigProvider
                    button={{
                        className: styles.linearGradientButton,
                    }}
                >
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                    initialValues={{
                      firstName: authInfo?.first_name || '',
                      lastName: authInfo?.last_name || '',
                      dateOfBirth: authInfo?.birthday ? dayjs(authInfo?.birthday).startOf('day') : null, // Format the date to exclude the time
                      homeAddress: authInfo?.address || '',
                      email: authInfo?.email || '',
                    }}
                  >
                  <Form.Item
                        label="First Name"
                        name="firstName"
                        labelCol={{ span: 7 }}  
                        wrapperCol={{ span: 17 }}  
                    >
                        <Input placeholder="John" />
                    </Form.Item>

                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        labelCol={{ span: 7 }}  
                        wrapperCol={{ span: 17 }}  
                    >
                        <Input placeholder="Doe" />
                    </Form.Item>

                    <Form.Item
                      label="Date of Birth"
                      name="dateOfBirth"
                      labelCol={{ span: 7 }}
                      wrapperCol={{ span: 17 }}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="YYYY-MM-DD"
                        placeholder="Select Date"
                        value={authInfo?.birthday ? dayjs(authInfo.birthday, 'YYYY-MM-DD') : null}
                      />
                    </Form.Item>


                    <Form.Item
                        label="Home Address"
                        name="homeAddress"
                        labelCol={{ span: 7 }}  
                        wrapperCol={{ span: 17 }}  
                    >
                        <Input.TextArea rows={2} placeholder="123 Main St, City, Country" />
                    </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelCol={{ span: 7 }}  
                    wrapperCol={{ span: 17 }}  
                  >
                    <Input placeholder="Email" disabled />
                  </Form.Item>
                  
                  <Form.Item
                    label="Password"
                    name="password"
                    labelCol={{ span: 7 }}  
                    wrapperCol={{ span: 17 }}  
                  >
                    <Input
                      type={visiblePassword.password ? 'text' : 'password'}
                      placeholder="Password"
                      suffix={
                        visiblePassword.password ? (
                          <EyeInvisibleOutlined onClick={() => togglePasswordVisibility('password')} />
                        ) : (
                          <EyeOutlined onClick={() => togglePasswordVisibility('password')} />
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="New Password"
                    name="new_password"
                    labelCol={{ span: 7 }}  
                    wrapperCol={{ span: 17 }}  
                  >
                    <Input
                      type={visiblePassword.newPassword ? 'text' : 'password'}
                      placeholder="New Password"
                      suffix={
                        visiblePassword.newPassword ? (
                          <EyeInvisibleOutlined onClick={() => togglePasswordVisibility('newPassword')} />
                        ) : (
                          <EyeOutlined onClick={() => togglePasswordVisibility('newPassword')} />
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    labelCol={{ span: 7 }}  
                    wrapperCol={{ span: 17 }}  
                  >
                    <Input
                      type={visiblePassword.confirmPassword ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      suffix={
                        visiblePassword.confirmPassword ? (
                          <EyeInvisibleOutlined onClick={() => togglePasswordVisibility('confirmPassword')} />
                        ) : (
                          <EyeOutlined onClick={() => togglePasswordVisibility('confirmPassword')} />
                        )
                      }
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Change
                    </Button>
                  </Form.Item>
                </Form>
                </ConfigProvider>
              </Modal>
            
          </Col>
        </Row>
      </nav>
    );
  } else {
    return (
      <nav className={`nav nav-active ${activeClass}`}>
        <Row justify="space-between" align="middle">
          <Col>
            <Link to="/">
              <IconWidget src={Logo} className="nav-logo" />
            </Link>
          </Col>
          <Col>
            {visibleMenu ? (
              <CloseOutlined
                style={{
                  color: "#003565",
                  cursor: "pointer",
                  fontSize: 20,
                  marginRight: 10,
                  marginTop: 10,
                }}
                onClick={() => setVisibleMenu(false)}
              />
            ) : (
              <MenuOutlined
                style={{
                  color: "#003565",
                  cursor: "pointer",
                  fontSize: 20,
                  marginRight: 10,
                  marginTop: 10,
                }}
                onClick={() => setVisibleMenu(true)}
              />
            )}
          </Col>
        </Row>
        <Drawer
          placement={"right"}
          visible={visibleMenu}
          className="custom-drawer"
          onClose={() => setVisibleMenu(false)}
        >
          <NavDrawer />
        </Drawer>
      </nav>
    );
  }
};

export default HomeNav;