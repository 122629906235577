import React from "react";
import ReactDOM from "react-dom/client"; // Updated import
import App from "./App";

// Create a root for the React application
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the App component using the new API
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
