import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { DeleteOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Toast from 'layouts/Partials/Toast';
import '../Dashboard/index.css';
const CBL = () => {
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [title, setTitle] = useState("");
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const location = useLocation();

  const isEditing = (record) => record.key === editingKey;

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const updatedData = { ...row };
      console.log('Sending updated data:', updatedData);
  
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}api/cbl/${key}`, 
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.status === 'success') {
        const newDataSource = dataSource.map(item => 
          item.key === key ? { ...item, ...updatedData } : item
        );
        setDataSource(newDataSource);
        setFilteredData(newDataSource);
        setEditingKey('');  
        Toast('Saved successfully!', 1);  
      } else {
        Toast('There was an error on server!', 2);
      }
    } catch (err) {
      console.error('Validation Failed:', err);
    }
  };

  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);
  
    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/cbl/${key}`);

    if (response.data.status == "success") {
        Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
    const formData = new FormData();
    formData.append('route', location.pathname);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/cbl/season`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        // const test = [];
        setTitle(response.data.season_type);
        const fetchedData = response.data.data.map((item, index) => ({
          ...item,
          key: String(item.id),
        }));
        setDataSource(fetchedData);
        setFilteredData(fetchedData);
      } catch (err) {
        Toast('There was an error on server!', 2);
      } 
    };

    fetchData();
  }, [location.pathname]);

  const handlePageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value, current: 1 });
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    // Define your columns here (same as before)
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, record, index) => {
        const { current, pageSize } = pagination;
        const displayIndex = (current - 1) * pageSize + index + 1;
        return displayIndex;
      },
      width: '55%',
    },
    { title: 'Name', dataIndex: 'name', render: (text, record) => renderEditableField('name', text, record) },
    { title: 'Gender', dataIndex: 'gender', render: (text, record) => renderEditableField('gender', text, record) },
    { title: 'Birthday', dataIndex: 'birthday', render: (text, record) => renderEditableField('birthday', text, record) },
    { title: 'Email', dataIndex: 'email', render: (text, record) => renderEditableField('email', text, record) },
    { title: 'Phone Number', dataIndex: 'phone_number', render: (text, record) => renderEditableField('phone_number', text, record) },
    { title: 'City', dataIndex: 'city', render: (text, record) => renderEditableField('city', text, record) },
    { title: 'Address', dataIndex: 'address', render: (text, record) => renderEditableField('address', text, record) },
    { title: 'Postal Code', dataIndex: 'postal_code', render: (text, record) => renderEditableField('postal_code', text, record) },
    { title: 'Emergency Contact Name', dataIndex: 'emegency_contact_name', render: (text, record) => renderEditableField('emegency_contact_name', text, record) },
    { title: 'Relationship', dataIndex: 'relationship', render: (text, record) => renderEditableField('relationship', text, record) },
    { title: 'Emergency Phone Number', dataIndex: 'emegency_phone_number', render: (text, record) => renderEditableField('emegency_phone_number', text, record) },
    { title: 'Competition Level', dataIndex: 'competition_level', render: (text, record) => renderEditableField('competition_level', text, record) },
    { title: 'Clubs', dataIndex: 'clubs', render: (text, record) => renderEditableField('clubs', text, record) },
    { title: 'Years Experience', dataIndex: 'years_experience', render: (text, record) => renderEditableField('years_experience', text, record) },
    { title: 'Preferred Position', dataIndex: 'preffered_position', render: (text, record) => renderEditableField('preffered_position', text, record) },
    { title: 'Height', dataIndex: 'height', render: (text, record) => renderEditableField('height', text, record) },
    { title: 'Dominant', dataIndex: 'dominant', render: (text, record) => renderEditableField('dominant', text, record) },
    { title: 'Medical Conditions', dataIndex: 'medical_conditions', render: (text, record) => renderEditableField('medical_conditions', text, record) },
    { title: 'Medications', dataIndex: 'medications', render: (text, record) => renderEditableField('medications', text, record) },
    { title: 'Photography', dataIndex: 'photography', render: (text, record) => renderEditableField('photography', text, record) },
    {
      title: 'Signature (Image Path)',
      dataIndex: 'signature_img_path',
      render: (text, record) => {
        const imageUrl = `${process.env.REACT_APP_API_BASE_URL}uploads/media/${text}`;
        const displayText = text && text.length > 10 
          ? `${text.slice(0, 5)}...${text.slice(-5)}`
          : text;
    
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={imageUrl}><img
              src={imageUrl}
              alt="Signature"
              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
            /></Link>
            <Tooltip title={text || 'No file path available'}> 
              <span>{displayText}</span>
            </Tooltip>
          </div>
        );
      },
    },
    { title: 'Signature (Date)', dataIndex: 'signature_date', render: (text, record) => renderEditableField('signature_date', text, record) },
    { title: 'Registering', dataIndex: 'registering', render: (text, record) => renderEditableField('registering', text, record) },
    { title: 'Available Training', dataIndex: 'available_training', render: (text, record) => renderEditableField('available_training', text, record) },
    { title: 'Waiver Release', dataIndex: 'wavier_release', render: (text, record) => renderEditableField('wavier_release', text, record) },
    { title: 'Name of Consenting Person', dataIndex: 'consenting_person', render: (text, record) => renderEditableField('consenting_person', text, record) },
    { title: 'Relationship to Child', dataIndex: 'relationship_child', render: (text, record) => renderEditableField('relationship_child', text, record) },
    { title: 'Link to Waiver Release of Liability', dataIndex: 'waiver_release_liability', render: (text, record) => renderEditableField('waiver_release_liability', text, record) },
    { title: 'Season', dataIndex: 'season', render: (text, record) => renderEditableField('season', text, record) },
    { title: 'User Id', dataIndex: 'user_id', render: (text, record) => renderEditableField('user_id', text, record) },
    { title: 'Created At', dataIndex: 'created_at', render: (text, record) => renderEditableField('created_at', text, record) },
    { title: 'Updated At', dataIndex: 'updated_at', render: (text, record) => renderEditableField('updated_at', text, record) },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button onClick={() => save(record.key)} type="link" className="btn-admin-edit"><SaveOutlined /></Button>
            <Button onClick={cancel} type="link" className="btn-admin-edit"><ReloadOutlined /></Button>
          </>
        ) : (
          <>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const renderEditableField = (field, text, record) => {
    const editable = isEditing(record);
    
    // Define required fields
    const requiredFields = ['name', 'email', 'phone_number']; // Add fields that must be validated
    
    return editable ? (
      <Form.Item
        name={field}
        style={{ margin: 0 }}
        rules={
          requiredFields.includes(field)
            ? [{ required: true, message: `${field} is required.` }]  // Validation for required fields
            : []  // No validation for optional fields
        }
      >
        <Input />
      </Form.Item>
    ) : text;
  };

  return (
    <AdminLayout>
      <Row className="table-nav mb-10">
        <Col className='d-flex admin-season-selection'>
          <h1>{title} Management</h1>
          <Select
            showSearch
            style={{ marginLeft: '20px' }}
            placeholder="Select a season"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { value: '1', label: 'CBL' },
              { value: '2', label: 'JCBL' },
              { value: '3', label: 'Academy' },
              { value: '4', label: 'CBL High School' },
            ]}
            onChange={async(value) => {
              const selectedOption = [
                { value: '1', label: 'CBL' },
                { value: '2', label: 'JCBL' },
                { value: '3', label: 'Academy' },
                { value: '4', label: 'CBL High School' },
              ].find(option => option.value === value);

              const formData = new FormData();
              formData.append("type", title);
              formData.append("season", selectedOption?.label);
              const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/cbl/season-select`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
              });
              
              
              let response_result = [];
              if (response.data.status == "success") {
                response_result = response.data.data;
              } else {
                response_result = [];
              }
              const fetchedData = response_result.map((item, index) => ({
                ...item,
                key: String(item.id),
              }));
              
              setDataSource(fetchedData);
              setFilteredData(fetchedData);

            }}
          />

        </Col>
        <Col>
          <Input
            className="mt-10"
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 10 }}
          />
        </Col>
      </Row>

      <div style={{ overflowX: 'auto', border: '1px solid #f0f0f0', borderRadius: '4px', overflowY: 'hidden' }}>
        <Form form={form} component={false}>
          <Table
            rowKey="key"
            pagination={{
              ...pagination,
              total: filteredData.length,
              onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
              showSizeChanger: true,
              onShowSizeChange: handlePageSizeChange,
            }}
            dataSource={filteredData.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize)}
            columns={columns}
          />
        </Form>
      </div>
    </AdminLayout>
  );
};

export default CBL;