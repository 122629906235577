import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Modal, Space } from "antd";
import { InboxOutlined } from '@ant-design/icons';

const PaintCanvas = ({ setSignatureData }) => {
  const sigCanvas = useRef({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [signature, setSignature] = useState(null); // Rename this variable

  // To clear the signature pad
  const clear = () => {
    sigCanvas.current.clear();
  };

  // Save signature
  const save = () => {
    if (!sigCanvas.current.isEmpty()) {
      const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setSignatureData(signatureData); // Use the prop to save the signature
      setSignature(signatureData); // Update the local state with the signature
      setIsModalVisible(false); // Close modal after saving
    }
  };

  return (
    <>
      <div 
        onClick={() => setIsModalVisible(true)}
        style={{
          background: 'none',
          border: 'none',
          display: 'flex',
          justifyContent: 'center'
        }}  
      >
        <InboxOutlined />
      </div>

      <Modal
        title="Sign Here"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered // This prop centers the modal
      >
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
        />
        <Space>
          <Button onClick={clear} style={{marginRight:'10px'}}>Clear</Button>
          <Button type="primary" onClick={save}>
            Save
          </Button>
        </Space>
      </Modal>

      {signature && ( // Use the renamed local state
        <div>
          <h3>Saved Signature:</h3>
          <img src={signature} alt="Signature" width='100%' style={{ border: "1px solid #000", marginTop:'10px' }} />
        </div>
      )}
    </>
  );
};

export default PaintCanvas;
