import React, {useState} from "react";
import AnimatedSection from 'components/AnimatedSection/AnimatedSection';
import { Link } from "react-router-dom";
import ModalRegistration from "components/Admin/Partials/ModalRegistration";
import "./CBCSlide.css";

const CBCSlide = () => {
    const [modal2Open, setModal2Open] = useState(false);
    const handleShowRegistration = () => {
        if (localStorage.getItem("client_token")) {
            setModal2Open(true);
        } else {
            window.location.href = "/signin"
        }
    }

    return (
        <div className="CBC-slide">
            <div className="CBC-slide-content">
                <Link to="#" className="slide-subtitle" onClick={handleShowRegistration}>THE AGE OF MODERN BASKETBALL</Link>
                {modal2Open && <ModalRegistration  modal2Open={modal2Open} setModal2Open={setModal2Open} />}
                <AnimatedSection type="top">
                <h1 className="slide-title">COASTAL BASKETBALL <br />
                CENTRE <span>(CBC)</span>
                </h1>
                </AnimatedSection>
                <AnimatedSection type="right">  
                <div className="slide-description">
                    The Coastal Basketball Centre (CBC) is the heartbeat of our basketball operations,
                    offering Custom design facilities to nurture talent and host competitive
                    events. Located in Bunbury, CBC is the first of several planned centres aimed at
                    providing basketball amenities to the region.
                </div>
                </AnimatedSection>
            </div>
        </div>
    )
}

export default CBCSlide;