import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Button, Col, Input, Row, Select, message, Typography, Upload, DatePicker, Radio, Checkbox, notification } from 'antd';
import "./Register1Page.css";

import axios from 'axios';
import PaintCanvas from 'components/Partials/PaintCanvas';

const { Option } = Select;
const { Link, Text } = Typography;
const { TextArea } = Input;

const Register1Page = ({ history }) => {
    const [value, setValue] = useState('SCHOOL TEAM');
    const [positionValue, setPositionValue] = useState('POINT GUARD(PG)');
    const [handValue, setHandValue] = useState('left');
    const [medicalValue, setMedicalValue] = useState('no');
    const [consentValue, setConsentValue] = useState('yes');
    const [liabilityValue, setLiabilityValue] = useState('no');
    const [registerValue, setRegisterValue] = useState('cbl');
    const [trainValue, setTrainValue] = useState('both');
    const [signatureData, setSignatureData] = useState(null);
    const [seasonData, setSeasonData] = useState([]);
    const [selectSeason, setSelectSeason] = useState([]);
    const [termsPdfUrl, setTermsPdfUrl] = useState(`${process.env.REACT_APP_API_BASE_URL}uploads/media/241025 Terms & Conditions.pdf`);
    const [formData, setFormData] = useState({
        name:'',
        gender:'',
        birthday:'',
        email:'',
        phone_number:'',
        city:'',
        address:'',
        postal_code:'',
        emegency_contact_name:'',
        relationship:'',
        emegency_phone_number:'',
        competition_level:'',
        clubs:'',
        years_experience:'',
        preffered_position:'',
        height:'',
        dominant:'',
        medical_conditions:'',
        medications:'',
        photography:'',
        signature_img_path:'',
        signature_date:'',
        registering:'',
        available_training:'',
        wavier_release:'',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCompetitionPInputChange = (e) => {
        const { value: selectedValue } = e.target;
        setValue(selectedValue); 
        setFormData({ ...formData, competition_level: selectedValue });
    };

    const handlePositionRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setPositionValue(selectedValue); 
        setFormData({ ...formData, preffered_position: selectedValue });
    };

    const handleDominRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setHandValue(selectedValue); 
        setFormData({ ...formData, dominant: selectedValue });
    };

    const handleMedicalRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setMedicalValue(selectedValue); 
        setFormData({ ...formData, medical_conditions: selectedValue });
    };

    const handlePhotoRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setConsentValue(selectedValue); 
        setFormData({ ...formData, photography: selectedValue });
    };

    const handleWavierRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setLiabilityValue(selectedValue); 
        setFormData({ ...formData, wavier_release: selectedValue });
    };

    const handleRegisterRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setRegisterValue(selectedValue); 
        setFormData({ ...formData, registering: selectedValue });
    };

    const handleTrainRadioChange = (e) => {
        const { value: selectedValue } = e.target;
        setTrainValue(selectedValue); 
        setFormData({ ...formData, available_training: selectedValue });
    };

    const handleSeasonGroupChange = (value) => {
        setFormData({ ...formData, season: value });
        const item = seasonData.find((item) => item.id === value);
        setSelectSeason(item);
    };

    const handleGenderGroupChange = (value) => {
        setFormData({ ...formData, gender: value });
    };

    const handleHeightGroupChange = (value) => {
        setFormData({ ...formData, height: value });
    };

    const handleExperienceGroupChange = (value) => {
        setFormData({ ...formData, years_experience: value });
    };

    const handleRelationGroupChange= (value) => {
        setFormData({ ...formData, relationship: value });
    };

    const handleUploadPlayerDataChange = (info) => {
        const { status } = info.file;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        if (info.fileList.length > 0) {
            setFormData({ ...formData, signature_img_path: info.fileList[0].originFileObj });
        }
    };

    const uploadProps = {
        name: 'file',
        multiple: false,
        action: null,
        onChange: handleUploadPlayerDataChange,
        beforeUpload: (file) => {
            return false;
        }
    };

    const openDangerNotification = (props) => {
        notification.config({
            placement: 'bottomLeft', 
            duration: 2,
        });
    
        let msg = '';
        let notificationType = '';
    
        if (props === 1) {
            msg = "Registration successful!";
            notificationType = 'success'; 
        } else if (props === 2) {
            msg = "Registration failed, please try again.";
            notificationType = 'error'; 
        } else {
            msg = "An error occurred during registration.";
            notificationType = 'error'; 
        }
    
        if (notificationType === 'success') {
            notification.success({
                message: 'Success Notification',
                description: msg,
            });
        } else {
            notification.error({
                message: 'Error Notification',
                description: msg,
            });
        }
    };

    const doRegister = async () => {
        console.log(formData);
        const { signature_img_path, ...rest } = formData;
        const formDataToSend = new FormData();
        
        Object.entries(rest).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                formDataToSend.append(key, value);
            }
        });
    
        formDataToSend.append('user_id', localStorage.getItem('user_id'));
        if (signatureData) {
            const blob = await (await fetch(signatureData)).blob(); // Convert to Blob
            formDataToSend.append('signature_img_path', blob, 'signature.png'); // Append Blob
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/cbl`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                openDangerNotification(1);
            } else {
                openDangerNotification(2);
            }
        } catch (error) {
            openDangerNotification(3);
        }
    }

    useEffect(() => {
        const fecthSeasonData = async () => {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/open_type_season`, 
                {'season_type':'cbl-jcbl'},
                {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setSeasonData(response.data.data);
        }
        fecthSeasonData();
    }, []); 
  
    useEffect(() => {
        const token = localStorage.getItem('client_token');
        if (!token) {
          history.push('/'); 
        }
      }, [history]);

    return (
        <HomeLayout>
            <div className='register-content'>
                <div className='register-adv'>
                    <h1>JOIN THE GAME <span className="mt-10">REGISTER</span> YOUR</h1>
                    <h1> INTEREST <span>NOW!</span></h1>
                    <p>Ready to showcase your skills? Whether you're aiming for the big leagues in the CBL or starting your journey in the JCBL,
                        this is your first step to becoming a part of Coastal Basketball. Register now to get started!
                    </p>

                    {/* <button className='btn-register home-little-letter white bold'>REGISTER NOW</button> */}
                </div>

                <div className='register2-logo'>
              
                </div>

                <div className='register-form'>
                    <div className='register-form-title'>
                        <h1>COASTAL BASKETBALL LEAGUE & JUNIOR COASTAL BASKETBALL LEAGUE REGISTRATION FROM</h1>
                    </div>

                    <Row className='register-form-row' gutter={[16, 16]}>
                        <Col xs={24} md={6}>
                            <h2>SEASON</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Select placeholder="SEASON" name="season" style={{ width: '100%' }} onChange={handleSeasonGroupChange}>
                                {seasonData.map(season => (
                                    <Option key={season.id} value={season.id}>
                                    {season.name}
                                    </Option>
                                ))}
                            </Select>
                            {selectSeason != undefined && selectSeason.id != null && (
                                <p className="text-left" 
                                   style={{
                                    marginTop:'5px', 
                                    color:'#515151', 
                                    fontWeight:'bold', 
                                    lineHeight:'1.7'
                                    }}
                                >
                                       Season Type: {selectSeason.type} <br/>
                                     Start Date: {selectSeason.start_date} <br/>
                                       End Date: {selectSeason.end_date}
                                </p>
                            )}
                        </Col>
                    </Row>
                    
                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>PERSONAL INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Input placeholder="FULL NAME" name="name" onChange={handleInputChange} />
                                </Col>
                                <Col xs={24} md={12}>
                                    <DatePicker
                                        className="date-picker"
                                        placeholder="DATE OF BIRTH"
                                        name="birthday"
                                        onChange={(date, dateString) => setFormData({ ...formData, birthday: dateString })}
                                    />
                                </Col>

                                <Col xs={24} md={12}>
                                    <Select placeholder="GENDER" name="gender" style={{ width: '100%' }} onChange={handleGenderGroupChange}>
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                        <Option value="other">Other</Option>
                                    </Select>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input placeholder="EMAIL ADDRESS" name="email" onChange={handleInputChange}/>
                                </Col>


                                <Col xs={24} md={12}>
                                    <Input placeholder="PHONE NUMBER" name="phone_number" onChange={handleInputChange}/>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input placeholder="ADDRESS" name="address" onChange={handleInputChange} />
                                </Col>

                                <Col xs={24} md={12}>
                                    <Input placeholder="CITY/SUBURB" name="city" onChange={handleInputChange} />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Input placeholder="POSTAL CODE" name="postal_code" onChange={handleInputChange} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>EMERGENCY CONTACT INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={12}>
                                    <Input placeholder="EMERGENCY CONTACT NAME" name="emegency_contact_name" onChange={handleInputChange} />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Select placeholder="RELATIONSHIP" style={{ width: '100%' }} onChange={handleRelationGroupChange}>
                                        <Option value="parent">Parent</Option>
                                        <Option value="guardian">Guardian</Option>
                                        <Option value="sibling">Sibling</Option>
                                        <Option value="other">Other</Option>
                                    </Select>
                                </Col>

                                <Col xs={24} md={24}>
                                    <Input placeholder="EMERGENCY CONTACT PHONE NUMBER" name="emegency_phone_number" onChange={handleInputChange} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>BASKETBALL EXPERIENCE</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Col xs={24} md={24}>
                                <h3>Highest Level of Competition Played:</h3>
                            </Col>
                            <Radio.Group onChange={handleCompetitionPInputChange} value={value} className="custom-radio-group">
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="SCHOOL TEAM">SCHOOL TEAM</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="LOCAL CLUB/ASSOCIATION">LOCAL CLUB/ASSOCIATION</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="STATE LEVEL">STATE LEVEL</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="NATIONAL LEVEL">NATIONAL LEVEL</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="OTHER">OTHER (PLEASE SPECIFY)</Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} md={12}>
                                    <h3 className="mt-10">Previous Teams/Clubs</h3>
                                    <Input placeholder="ADD YOUR TEAM" name="clubs"  onChange={handleInputChange} />
                                </Col>

                                <Col xs={24} md={12}>
                                    <h3 className="mt-10">Years of Experience</h3>
                                    <Select placeholder="SELECT" style={{ width: '100%' }} onChange={handleExperienceGroupChange}>
                                        <Option value="2">2</Option>
                                        <Option value="4">4</Option>
                                        <Option value="6">6</Option>
                                        <Option value="8">8</Option>
                                        <Option value="10">10</Option>
                                        <Option value="More than 10">More than 10</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Row className='row-padding-top'>
                                <Col xs={24} md={24}>
                                    <h3>Preffered Playing Position(s):</h3>
                                </Col>
                                <Radio.Group onChange={handlePositionRadioChange} value={positionValue} className="custom-radio-group">
                                    <Row gutter={[8, 8]}>
                                        <Col  xs={24} md={12}>
                                            <Radio.Button value="POINT GUARD(PG)">POINT GUARD(PG)</Radio.Button>
                                        </Col>
                                        <Col  xs={24} md={12}>
                                            <Radio.Button value="SHOOTING GUARD(SG)">SHOOTING GUARD(SG)</Radio.Button>
                                        </Col>
                                        <Col  xs={24} md={12}>
                                            <Radio.Button value="SMALL FORWARD(SF)">SMALL FORWARD(SF)</Radio.Button>
                                        </Col>
                                        <Col  xs={24} md={12}>
                                            <Radio.Button value="POWER FORWARD(PF)">POWER FORWARD(PF)</Radio.Button>
                                        </Col>
                                        <Col  xs={24} md={12}>
                                            <Radio.Button value="CENTER(C)">CENTER(C)</Radio.Button>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} md={12}>
                                    <h3>Height(cm):</h3>
                                    <Select placeholder="YOUR HEIGHT" style={{ width: '100%' }} onChange={handleHeightGroupChange}>
                                        <Option value="140">140</Option>
                                        <Option value="150">150</Option>
                                        <Option value="160">160</Option>
                                        <Option value="170">170</Option>
                                        <Option value="180">180</Option>
                                        <Option value="190">190</Option>
                                        <Option value="200">200</Option>
                                        <Option value="More than 200">More than 200</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]} className="mt-10">
                                <Col xs={24} md={24}>
                                    <Col xs={24} md={24}>
                                        <h3 className="mt-10">Dominant Hand</h3>
                                    </Col>
                                    <Radio.Group onChange={handleDominRadioChange} value={handValue} className="custom-radio-group" style={{ "width": "100%" }}>
                                        <Row gutter={[8, 8]}>
                                            <Col xs={24} md={12}>
                                                <Radio.Button value="right">RIGHT</Radio.Button>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Radio.Button value="left">LEFT</Radio.Button>
                                            </Col>
                                        </Row>
                                    </Radio.Group>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>MEDIAL INFORMATION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Col xs={24} md={24}>
                                <h3>Any Medical Conditions or Allergies: </h3>
                            </Col>
                            <Radio.Group onChange={handleMedicalRadioChange}  value={medicalValue} className="custom-radio-group" style={{ "width": "100%"}}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="no">NO</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="yes">YES(PLEASE SPECIFY)</Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>
                            <Col xs={24} md={24}>
                                <h3>Medications Currently Taking: </h3>
                            </Col>
                            <Col xs={24} md={24}>
                                <Input placeholder="PLEASE PROVIDE YOUR MEDICAL TAKING" name="medications" onChange={handleInputChange}  />
                            </Col>
                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>CONSENT & AGREEMENT</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Col xs={24} md={24}>
                                <h3>Photography/Videography Consent: </h3>
                            </Col>
                            <Radio.Group onChange={handlePhotoRadioChange} value={consentValue} className="custom-radio-group" style={{ "width": "100%" }}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="yes" className="register2-liability">I CONSENT TO COASTAL BASKETBALL USING PHOTOS OR VIDEOS OF ME/MY CHILD FOR PROMOTIONAL PURPOSES.</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="no">I DO NOT CONSENT</Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>

                            <Col xs={24} md={24}>
                                <h3>Wavier and Release of Liability: </h3>
                            </Col>

                            <Radio.Group onChange={handleWavierRadioChange} value={liabilityValue} className="custom-radio-group wavier-liability" style={{ "width": "100%" }}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="yes" className="register2-liability" style={{ lineHeight: 1.4, paddingRight: "30px" }}>I AGREE TO THE TERMS AND CONDITIONS OF PARTICIPATION, INCLUDING THE WAVIER AND RELEASE OF LIABILITY.
                                        </Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>

                            <Row gutter={[8, 8]}>
                                <Col xs={24} md={8}>
                                    <TextArea className="mb-10" placeholder="Name of Consenting Person" name="consenting_person" onChange={handleInputChange}  />
                                </Col>
                                <Col xs={24} md={8}>
                                    <TextArea className="mb-10" placeholder="Relationship to Child" name="relationship_child" onChange={handleInputChange}  />
                                </Col>
                                <Col xs={24} md={8}>
                                    <Button type="primary" style={{width:'100%',height:'42px'}}>
                                        <Link href={termsPdfUrl} target="_blank" rel="noopener noreferrer" style={{color:'white'}}>
                                            Terms & Conditions
                                        </Link>
                                    </Button>

                                    {/* <Input placeholder="Link to Waiver Release of Liability(I will supply the doc)" name="waiver_release_liability" onChange={handleInputChange}  /> */}
                                </Col>
                            </Row>

                            <Row className="signature-section" gutter={[32, 32]}>
                                <Col xs={24} md={12}>
                                    <Text>Signature:</Text>
                                    <div className="signature-uploader">
                                        <PaintCanvas setSignatureData={setSignatureData} /> {/* Pass setter to child */}
                                        <p className="ant-upload-text">CLICK TO SIGN</p>
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Text>Date:</Text>
                                    <DatePicker
                                        className="date-picker"
                                        placeholder="SELECT A DATE"
                                        name="signature_date"
                                        onChange={(signature_date, dateString) => setFormData({ ...formData, signature_date: dateString })}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>LEAGUE RESTRICTION</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Col xs={24} md={24}>
                                <h3>Registering For: </h3>
                            </Col>
                            <Radio.Group onChange={handleRegisterRadioChange} value={registerValue} className="custom-radio-group" style={{ "width": "100%" }}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="cbl">COASTAL BASKETBALL LEAGUE(CBL)</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="jcbl" className="custom-radio-with-list">
                                            <div>
                                                <span>JUNIOR COASTAL BASKETBALL LEAGUE (JCBL)</span>
                                                <ul>
                                                    <li>YEAR 9/10</li>
                                                    <li>YEAR 11/12</li>
                                                </ul>
                                            </div>
                                        </Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>

                            <Col xs={24} md={24}>
                                <h3>Available for Training/Matches: </h3>
                            </Col>
                            <Radio.Group onChange={handleTrainRadioChange} value={trainValue} className="custom-radio-group" style={{ "width": "100%", "marginBottom": "20px" }}>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="weekends">WEEKENDS</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Radio.Button value="weekdays">WEEKDAYS(EVENINGS)</Radio.Button>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Radio.Button value="both">BOTH</Radio.Button>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row className='register-form-row' gutter={[8, 8]}>
                        <Col xs={24} md={6}>
                            <h2>SUBMIT</h2>
                        </Col>
                        <Col xs={24} md={18}>
                            <Button type="primary" className="submit-button home-little-letter white bold" onClick={() => doRegister()}>
                                SUBMIT YOUR FORM
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </HomeLayout>
    )
}

export default withRouter(Register1Page);