import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { Modal, Row, Col, Button, ConfigProvider, DatePicker, Form, Input, Checkbox, Spin } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined, EyeInvisibleOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"; 
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';
import './index.css';
import useStyle from './useStyle';
import Logo from "assets/png/logo.png";

const Signup = (props) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true); 
    const [signinResponse, setSigninResponse] = useState("");
    const [signupResponse, setSignupResponse] = useState("");
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const { styles } = useStyle();

    const onSigninFinish = async (values) => {
        console.log("Form Values:", values);
        try {
            const formData = new FormData();
            formData.append("password", values.password);
            formData.append("email", values.email);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signin`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('clientToken')}`,
                    },
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("client_token", response.data.token);
                setIsAuth(true);
            } else {
                setSigninResponse(response.data.message);
            }
        } catch (error) {
            console.error("Signin error:", error);
        }
    };

    const onFinish = async (values) => {
        setSignupResponse("");
        console.log("Form Values:", values);

        if (values.password !== values.cpassword) {
            setPasswordsMatch(false);
            return;
        }

        setPasswordsMatch(true);
        setLoading(true);

        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            const formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("dateOfBirth", values.dateOfBirth.format('YYYY-MM-DD'));
            formData.append("homeAddress", values.homeAddress);
            formData.append("password", values.password);
            formData.append("email", values.email);
            formData.append("ipAddress", userIp);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signup`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.data.res === "success") {
                localStorage.setItem("email", response.data.email);
                window.location.href = "/otp-verify";
            } else if (response.data.res === "verifying") {
                setSignupResponse(response.data.message);
                setTimeout(() => {   
                    window.location.href = "/otp-verify"; // Redirect after 2 seconds
                }, 2000);
            } else if (response.data.res === "exist") {
                setSignupResponse("This account already exists. Please sign in.");
            } else {
                setSignupResponse("We encountered a problem during signup. Please try again in a moment.");
            }
        } catch (error) {
            console.error("Signup error:", error);
        } finally {
            setLoading(false);
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleBack = () => {
        window.history.back();
      }

    return (
        <div className="client-auth-content">
            <div className="client-auth-card px-0">
            <a href="#" onClick={handleBack} type="button"  className="btn-back-icon btn-back-signup-icon"><ArrowLeftOutlined style={{fontSize:'20px'}} /></a>
                <div className="client-signup">
                    <div className="mb-30 center">
                        <img src={Logo} alt="Modal Image" />
                    </div>
                    <h2 className="mb-30 text-center">Sign up</h2>
                    
                    <ConfigProvider
                        button={{
                            className: styles.linearGradientButton,
                        }}
                    >
                        <Form
                            name="signup"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: "Please enter your first name" }]}
                            >
                                <Input placeholder="John" />
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: "Please enter your last name" }]}
                            >
                                <Input placeholder="Doe" />
                            </Form.Item>

                            <Form.Item
                                label="Date of Birth"
                                name="dateOfBirth"
                                rules={[{ required: true, message: "Please select your date of birth" }]}
                            >
                                <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
                            </Form.Item>

                            <Form.Item
                                label="Home Address"
                                name="homeAddress"
                                rules={[{ required: true, message: "Please enter your home address" }]}
                            >
                                <Input.TextArea rows={2} placeholder="123 Main St, City, Country" />
                            </Form.Item>

                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[
                                    { required: true, message: "Please enter your email address" },
                                    { type: "email", message: "Please enter a valid email" },
                                ]}
                            >
                                <Input placeholder="john.doe@example.com" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                validateStatus={passwordsMatch ? '' : 'error'} // Remove icons by leaving the status blank
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input.Password
                                    placeholder="Password"
                                    iconRender={(visible) =>
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    }
                                    visibilityToggle={{
                                        visible: passwordVisible,
                                        onVisibleChange: setPasswordVisible,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="cpassword"
                                validateStatus={passwordsMatch ? '' : 'error'} // Remove icons by leaving the status blank
                                rules={[
                                    { required: true, message: 'Please confirm your password' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Passwords do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    placeholder="Confirm Password"
                                    iconRender={(visible) =>
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    }
                                    visibilityToggle={{
                                        visible: confirmPasswordVisible,
                                        onVisibleChange: setConfirmPasswordVisible,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <p style={{ color: 'red', marginBottom: '10px' }}>{signupResponse}</p>
                                <Button type="primary" htmlType="submit" block>
                                    {loading ? (
                                        <>
                                            <Spin size="small" style={{ marginRight: 8, color: 'white' }} />
                                            Loading...
                                        </>
                                    ) : (
                                        <>
                                            Next <ArrowRightOutlined />
                                        </>
                                    )}
                                </Button>
                                <div className="center">
                                    <Link to="/signin" className="client-auth-text mt-30">Do you have an account?</Link>
                                </div>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Signup);