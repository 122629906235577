import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Checkbox, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

const User = ({ history }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login'); 
    }
  }, [history]);

  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [newItemKey, setNewItemKey] = useState(null); 
  const [isNewItem, setIsNewItem] = useState(false); 
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/user`);
        console.log("3333333", response.data);
        const fetchedData = response.data
          .filter(item => item.is_deleted != 1)
          .map((item, index) => ({
            ...item,
            key: String(item.id),
          }));
        
        setDataSource(fetchedData);
        setFilteredData(fetchedData);
      } catch (err) {
        Toast('There was an error on server!', 2);
      } 
    };
  
    fetchData();
  }, []);

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      Toast('No users selected for deletion.', 2);
      return;
    }
  
    try {
      const idsToDelete = selectedRowKeys;
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/user/bulk-delete`, {
        data: { ids: idsToDelete },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (response.data.status === 'success') {
        const updatedData = dataSource.filter(item => !idsToDelete.includes(item.key));
        setDataSource(updatedData);
        setFilteredData(updatedData);
        setSelectedRowKeys([]);
  
        Toast('Selected users deleted successfully!', 1);
      } else {
        Toast('Failed to delete selected users.', 2);
      }
    } catch (error) {
      console.error('Bulk delete error:', error);
      Toast('There was an error deleting users.', 2);
    }
  };
  

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
    onSelectAll: (selected, selectedRows) => {
      setSelectedRowKeys(selected ? selectedRows.map(row => row.key) : []);
    }
  };
  
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      first_name: '',
      last_name: '',
      birthday: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  

  const cancel = () => {
    if (isNewItem) {
      setDataSource(prev => prev.filter(item => item.key !== newItemKey));
      setFilteredData(prev => prev.filter(item => item.key !== newItemKey));
      setIsNewItem(false); 
    }
    setEditingKey('');
    form.resetFields(); 
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const updatedData = { ...row };
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/user/${key}`, 
        updatedData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.data.status === 'success') {
        const newDataSource = dataSource.map(item => 
          item.key === key ? { ...item, ...updatedData } : item
        );
        setDataSource(newDataSource);
        setFilteredData(newDataSource);
        setEditingKey('');  
        Toast('Saved successfully!', 1);  
      } else {
        Toast('There was an error on server!', 2);
      }
    } catch (err) {
      console.error('Validation Failed:', err);
    }
  };
  
  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);
  
    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/user/${key}`);

    if (response.data.status == "success") {
        Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };
  
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length}
          checked={selectedRowKeys.length === dataSource.length}
          onChange={(e) => {
            const checked = e.target.checked;
            const newSelectedKeys = checked ? dataSource.map(item => item.key) : [];
            setSelectedRowKeys(newSelectedKeys);
          }}
        />
      ),
      dataIndex: 'checkbox',
      render: (_, record) => (
        <Checkbox
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedRowKeys(prev =>
              checked ? [...prev, record.key] : prev.filter(key => key !== record.key)
            );
          }}
        />
      ),
    },   
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, __, index) => index + 1 + (pagination.current - 1) * pagination.pageSize,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      width: '10%',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="first_name"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input />
          </Form.Item>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      width: '10%',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="last_name"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input />
          </Form.Item>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: 'Birthday',
      dataIndex: 'birthday',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="birthday"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Birthday is required' }]}
          >
            <Input type="date" />
          </Form.Item>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Form.Item
            name="address"
            style={{ margin: 0 }}
            rules={[{ required: true, message: 'Address is required' }]}
          >
            <Input />
          </Form.Item>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: 'Ip Address',
      dataIndex: 'ip_address',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Access Time',
      dataIndex: 'access_time',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return record.status == 1 ? (
          <Tag style={{ color: 'white', backgroundColor: 'green', padding: '2px 10px', border: 'none', width: '85px', textAlign: 'center' }}>
            Verified
          </Tag>
        ) : (
          <Tag style={{ color: 'white', backgroundColor: '#F83535', padding: '2px 10px', border: 'none', width: '85px' }}>
            Non Verified
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button onClick={() => save(record.key)} type="link">
              <SaveOutlined  style={{fontSize:'20px'}} />
            </Button>
            <Button onClick={cancel} type="link">
              <ReloadOutlined  style={{fontSize:'20px'}} />
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => edit(record)} type="link">
              <EditOutlined  style={{fontSize:'20px'}}/>
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link">
                <DeleteOutlined style={{fontSize:'20px', color:'red'}}/>
              </Button>
            </Popconfirm> 
          </>
        );
      },
    },
  ];
  
  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col><h1>User Management</h1></Col>
        <Col className="d-flex">
          {selectedRowKeys.length > 0 && (
            <Button
              type="primary"
              danger
              onClick={handleBulkDelete}
              icon={<DeleteOutlined />}
              style={{ marginRight: '16px', marginTop: '10px', height: '43px' }}
            >
              Delete {selectedRowKeys.length} {selectedRowKeys.length > 1 ? 'Users' : 'User'}
            </Button>
          )}
          <Input
            className="mt-10"
            placeholder="Search..."
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 10 }}
          />
        </Col>
      </Row>
      <div className="admin-user">
        <Form form={form} component={false}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              ...pagination,
              showSizeChanger: true,
              onChange: (page, pageSize) => setPagination({ ...pagination, current: page, pageSize }),
            }}
            rowClassName="editable-row"
            rowKey="key"
            scroll={{ x: 'max-content' }}
          />
        </Form>
      </div>

    </AdminLayout>
  );
};

export default User;